import React from 'react';
import { Link } from 'react-router-dom';
import './Scoreboard.css'; 

const Scoreboard = () => {
  return (
    <div className='scoreboard'>
      <div className='goal-home'>
        <h2 className='goal-title'>Home</h2>
        <p className='goal-numb'>05</p>
      </div>
      <div className='time'>
        <p className='time-numb'>10:10</p>
        <p className='time-period'>5</p>
      </div>
      <div className='goal-visitor'>
        <h2 className='goal-title'>Visitor</h2>
        <p className='goal-numb'>05</p>
      </div>

      <Link to="/commande" className="commande-link">
        Aller à la page commande
      </Link>
    </div>
  );
};

export default Scoreboard;
