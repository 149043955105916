import React, { useState } from 'react';
import axios from 'axios';
import './commande.css';

const Commande = () => {
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://scoreboardapi.onrender.com/auth/login', { password });
      if (response.data.success) {
        setIsLoggedIn(true);
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      setErrorMessage('Erreur du serveur.');
    }
  };

  if (isLoggedIn) {
    return (
      <div className='container'>
        <div className='commande'>
          <p>ici les commandes</p>
        </div>
      </div>
    );
  }

  return (
    <div className='container'>
      <div className='login'>
        <input
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder='Mot de passe'
        />
        <button onClick={handleLogin}>Se connecter</button>
        {errorMessage && <p>{errorMessage}</p>}
      </div>
    </div>
  );
};

export default Commande;
