import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Commande from './Commande';
import Scoreboard from './Scoreboard';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Scoreboard} />
        <Route path="/commande" component={Commande} />
      </Switch>
    </Router>
  );
};

export default App;
